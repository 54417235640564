import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../layout/footer5';
import { Header12 } from '../layout/header12';



class Contact4 extends Component {


    render() {
        return (
            <div style={{ background: "#000" }}>
                <Header12 />
                <div className="page-content" >
                    <div className="section-full content-inner contact-style-1">
                        <div className="container text-center flex justify-center" style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <h1 className="text-white text-center border-bottom px-auto pb-2" style={{ width: "300px" }}>Contact Us</h1>
                        </div>
                        <div className="container">
                            <div className="row contactContainer bg-contacts">
                                <div className='half-ray-circle ray-contacts-left'></div>

                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link className="icon-cell"><i className="ti-location-pin"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Address</h5>
                                            <p>Office No.705, Sama Tower, Next to Fairmont Hotel, Sheikh Zayed Road, Dubai, UAE</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link className="icon-cell"><i className="ti-email"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Email</h5>
                                            <p>mj@mskcommerce.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link className="icon-cell"><i className="ti-mobile"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Phone</h5>
                                            <p>+971 4 2870438 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30" style={{ zIndex: "2" }}>
                                    <div className="p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link className="icon-cell"><i className="ti-printer"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Fax</h5>
                                            <p> <br /> </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='half-ray-circle ray-contacts-right'></div>

                            </div>
                            <div className='flex row contact-form '>
                                <div className='col-lg-6 col-md-12 p-5'>
                                    <div className="p-a30 clearfix radius-sm">
                                        <h3 className='text-white'>Send Message Us</h3>
                                        <div className="dzFormMsg"></div>
                                        <form method="post" className="dzForm" action="script/contact.php">
                                            <input type="hidden" value="Contact" name="dzToDo" />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="dzName" type="text" required className="form-control requiredInput" placeholder="Your Name (Required)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="dzEmail" type="email" className="form-control requiredInput" required placeholder="Your Email (Required)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="dzOther[Subject]" type="text" required className="form-control requiredInput" placeholder="Subject" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <textarea name="dzMessage" rows="4" className="form-control dzMessage" required placeholder="Your Message..."></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                            <input className="form-control d-none" style={{ display: "none" }} data-recaptcha="true" required data-error="Please complete the Captcha" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button name="submit" type="submit" value="Submit" className="site-button"> <span>Submit</span> </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='col-lg-6 lg-none p-5 contact-us-img' ></div>
                            </div>
                            <div className="m-b30 d-flex">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14437.25133789963!2d55.2843871!3d25.2263862!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f698983d196a1%3A0x66c0650dd26e87da!2sFairmont%20Dubai!5e0!3m2!1sen!2s!4v1679721858331!5m2!1sen!2s" width="800" height="600" className="align-self-stretch radius-sm" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.2574754132!2d55.28379615093054!3d25.228251436551023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f42ef0c76dd9d%3A0xa1a3515c191b500e!2sSama%20Tower!5e0!3m2!1sen!2s!4v1660050062019!5m2!1sen!2s" title="map" className="align-self-stretch radius-sm" style={{border:"0",width:"100%",minHeight:"100%"}} allowfullscreen></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Contact4;