import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const bg = require("../../images/background/bg2.png");

const Footer5 = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "d9b2e0f5fc72cb94792110e8ff2028f3-us16"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const isTablet = useMediaQuery({
    query: "((min-width:650px) and (max-width: 900px))"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 400px)"
  });
  const scrolling = (element) => {
    var my_element = document.getElementById(element);

    my_element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  return (
    <>
      <footer className="site-footer" style={{ background: "transparent" }}>
        <div
          className="footer-top"
        >

          <div className="container" style={{ background: "transparent !Important" }}>
            <div className="ray-product-end" />
            <div className="row">
              <div className="col-md-3 col-5 col-xl-2 col-lg-2 col-sm-6 footer-col-4">
                <div className="widget widget_services border-0">
                  <h5 className="footer-title text-white " style={{ borderBottom: "1px solid #fff", width: "100px" }}>Company</h5>
                  <ul className="ul-footer-menu">
                    <li className="">
                      <Link to={"/aboutUs"}>About Us</Link>
                    </li>
                    <li >
                      <Link to={"/prices"}>Prices</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-7 col-xl-2 col-lg-3 col-sm-6 footer-col-4">
                <div className="widget widget_services border-0">
                  <h5 className="footer-title text-white" style={{ borderBottom: "1px solid #fff", width: '7rem' }}>Useful Link</h5>
                  <ul className="ul-footer-menu">
                    <li className="">
                      <Link to={"/products"}>products</Link>
                    </li>
                    <li>
                      <Link to={"/contactUs"}>Contact</Link>
                    </li>
                    <li>
                      <Link to='/blogList'>News</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 col-lg-3 col-sm-6 footer-col-4">
                <div className="widget widget_getintuch">
                  <h5 className="footer-title text-white" style={{ borderBottom: "1px solid #fff", width: '7rem' }}>Contact us</h5>
                  <ul className="ul-footer-menu address">
                    <li>
                      <i className="ti-location-pin"></i>
                      <strong style={{ color: '#fff' }}>address</strong> OFFICE NO. 902B, SAMA TOWER, SHEIKH ZAYED ROAD, DUBAI, UAE.
                      POBOX: 418669{" "}
                    </li>
                    <li>
                      <i className="ti-mobile"></i>
                      <strong style={{ color: '#fff' }}>phone</strong>+971 4 2870438(24/7 Support Line)
                    </li>
                    <li>
                      <i className="ti-email"></i>
                      <strong style={{ color: '#fff' }}>email</strong>mj@mskcommerce.com
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-xl-4 col-lg-4 col-sm-6 footer-col-4 ">
                <div className="widget">
                  <h5 className="footer-title text-white"
                    style={{ borderBottom: "1px solid #fff", width: '14rem', textWrap: "noWrap" }}
                  >
                    Subscribe To Our Newsletter
                  </h5>
                  <p className="text-capitalize m-b20">
                    MSK Trading is a pioneer company which is active in
                    several Commercial fields such as: Oil Products ,
                    Petrochemicals & Oil and Gas Industry
                  </p>
                  <div className="subscribe-form m-b20">
                    <form
                      className="dzSubscribe"
                      ref={form}
                      onSubmit={sendEmail}
                    >
                      <div className="dzSubscribeMsg"></div>
                    </form>
                  </div>
                  {/* <ul className="list-inline m-a0">

                    <li>
                      <Link className="site-button linkedin circle " to='#'
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            event.preventDefault();
                            window.open('https://www.linkedin.com/company/pronix-international-trading/')
                          }
                        }}
                        onClick={() => window.open('https://www.linkedin.com/company/pronix-international-trading/')}>
                        <i className="fa fa-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link className="site-button instagram circle " to='#'
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            event.preventDefault();
                            window.open('https://www.instagram.com/accounts/login/?next=%2Fpronix_international%2F')
                          }
                        }}
                        onClick={() => window.open('https://www.instagram.com/accounts/login/?next=%2Fpronix_international%2F')}>
                        <i className="fa fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <div className="whatsAppInFooter"
                        onClick={() =>
                          window.open(
                            "https://wa.me/971566879135"
                          )
                        }
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            event.preventDefault();
                            window.open("https://wa.me/971566879135")
                          }
                        }}
                      >
                        <WhatsAppIcon color='#fff' />
                      </div>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom" style={{
          border: "1px solid #fff", borderWidth: "1px 0 0 0"
        }}>
          <div className="container">
            <div className="row text-center justify-content-center">
              <span className="text-center">mskcommerce.com </span>{" "}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer5;
